import { Link as ExternalLink } from "common/components";
import {
  AMAZON_PAGE_UI_SRC,
  AMAZON_UI_SRC,
  DEFAULT_LOCALE,
  JQUERY_SRC,
  LOCALE_KEY,
  MAIN_PAGE_TITLE,
} from "config/constants";
import { FEATURE_FLAGS } from "config/featureFlags";
import { PARTIALS_MAP } from "config/partial-constants";
import { DomElement } from "domhandler";
import UrlHelper from "helpers/url-helper";
import React, { Suspense, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import ReactHtmlParser from "react-html-parser";
import { Link, useLocation } from "react-router-dom";
import InternalServerErrorPage from "view/Page/InternalServerError";
import NotFoundPage from "view/Page/NotFound";

import { useAppContext } from "../../../AppContext";
import { METADATA_TYPE_TO_PATH } from "../../../config/route-constants";
import ClickstreamHelper, { FeatureMetric } from "../../../lib/helpers/clickstream-helper";
import RenderingHelper, { HttpResponse } from "../../../lib/helpers/rendering-helper";
import { MetadataType } from "../../../lib/interfaces/metadata";
import LoadingScreen from "../../../modules/components/LoadingScreen";
import StencilizedLegoPage from "../StencilizedLegoPage";

interface ILegoPageProps {
  path?: string;
}

declare global {
  interface Window {
    AmazonUIPageJS: unknown;
    P: unknown;
  }
}

export enum LinkType {
  POPULAR_ARTICLES = "popular-articles",
}

export const replaceMetaTagLink = (node: DomElement): string => {
  const { href } = node.attribs ?? { href: "" };
  let metaTagHref = href;
  if (metaTagHref?.startsWith("/help")) {
    /* At the time of writing this, we need to segregate the metadata key per each articles apart
     * from each other without modifying too much in the widget file
     * due to the nature of sharing widgets between ITPortal and KBPortal.
     * The temporary solution is to replace the /help/categories path with the metadata type path
     * https://sim.amazon.com/issues/CaPe-7531
     */
    metaTagHref = metaTagHref.replace("/help", "");
    metaTagHref = metaTagHref.replace(
      "/categories",
      METADATA_TYPE_TO_PATH[node.parent?.attribs?.["data-meta-type"] as MetadataType]
    );
  }

  return metaTagHref;
};

/**
 * Handles scrolling to an element on the same page
 * @param hash the id of the element to scroll to
 * @example scrollToAnchor("#some-div")
 */
const scrollToAnchor = (hash?: Location["hash"]) => {
  if (hash) {
    const escapedHash = CSS.escape(hash.substring(1)); // escape to handle hashes that might be UUIDs
    const element = document.querySelector(`#${escapedHash}`);
    element?.scrollIntoView({ behavior: "smooth" });
  }
};

const LegoPage = (props: ILegoPageProps) => {
  const { setUserProfileReady } = useAppContext();
  const [page, setPage] = useState<any>();
  const [isStencilized, setIsStencilized] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const PARTIALS_REGEX = /{{[[\s]]*cms:partial:shared\/([^ }]*)[[\s]]*}}/g;
  const { clickStreamUrls } = useAppContext();
  const { hash } = useLocation();
  const language: string = window.localStorage.getItem(LOCALE_KEY) || DEFAULT_LOCALE;

  useEffect(() => {
    fetchPage();
  }, []);

  useEffect(() => {
    if (hash && page) {
      scrollToAnchor(hash);
    }
  }, [hash, page]);

  const fetchPage = () => {
    setLoading(true);
    let path;
    if (props.path !== undefined) {
      path = props.path;
    } else {
      path = window.location.pathname + window.location.search;
    }
    RenderingHelper.getData(path, language)
      .then(([pageContent, status]) => {
        // baseURL is used to determine if a link is local or external
        const baseURL = new URL(window.location.toString());
        setLoading(false);
        switch (status) {
          case HttpResponse.Success: {
            if (typeof pageContent === "object") {
              pageContent = pageContent.content;
            }
            //If the html includes the stencil tag from LEGO, render using ITSContentRendering
            if (FEATURE_FLAGS.STENCIL_CONTENT && pageContent.includes("<render-with-stencil>")) {
              setIsStencilized(true);
              setPage(<StencilizedLegoPage html={pageContent} path={props.path} contentType="html" />);
              break;
            }

            const newPage = pageContent.replace(PARTIALS_REGEX, "<$1/>");
            setPage(() =>
              ReactHtmlParser(newPage, {
                transform: (node, index) => {
                  // replacing <a> tags with react router Link elements. Needed for routing in SPA context
                  // Exception: Do not replace when <a> has class='tab__link'. This messes up the Tab widget
                  const isLink = node.type === "tag" && node.name === "a" && node.attribs.class != "tab__link";

                  if (isLink) {
                    const href = replaceMetaTagLink(node);
                    const url = new URL(href, baseURL); // url will take hostname from baseURL if it is missing in href

                    const isPopularArticlesLink =
                      node.parent?.parent?.attribs["data-type"] === LinkType.POPULAR_ARTICLES;

                    const handlePopularArticlesClick = async () => {
                      await ClickstreamHelper.createFeatureMetric({
                        feature: FeatureMetric.USEFUL_LINKS,
                        language: language,
                        uri: node.attribs.href,
                        ...clickStreamUrls,
                      });
                    };

                    const popularLinkTracker = isPopularArticlesLink && {
                      onClick: handlePopularArticlesClick,
                    };

                    if (url.hostname === baseURL.hostname) {
                      const { hash, pathname, search } = url;
                      return (
                        <Link to={{ hash, pathname, search }} key={index} {...popularLinkTracker}>
                          {node.children[0].data}
                        </Link>
                      );
                    } else {
                      return (
                        <ExternalLink external={true} key={index} href={node.attribs.href} {...popularLinkTracker}>
                          {node.children[0].data}
                        </ExternalLink>
                      );
                    }
                  }

                  // replacing the partial tags with react elements
                  if (node.type === "tag" && PARTIALS_MAP[node.name]) {
                    const ReactElement = PARTIALS_MAP[node.name];
                    const name = node.name;
                    return (
                      <Suspense fallback={null} key={index}>
                        <ReactElement name={name} />
                      </Suspense>
                    );
                  }
                },
              })
            );
            setUserProfileReady(true);
            break;
          }
          case HttpResponse.NotFound: {
            setPage(<NotFoundPage />);
            break;
          }
          case HttpResponse.InternalServerError: {
            setPage(<InternalServerErrorPage />);
            break;
          }
          default: {
            setPage(<InternalServerErrorPage />);
            break;
          }
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        setPage(<InternalServerErrorPage />);
      });
  };

  // Sometimes, scripts are not loaded in order or haven't finished loading yet before AMAZON_PAGE_UI_SRC is loaded.
  const canLoadLegoScript = useMemo(() => window.AmazonUIPageJS || window.P, [window.AmazonUIPageJS, window.P]);

  useEffect(() => {
    // Set window title to extracted article title if content isn't stencilized
    if (!loading && page && !isStencilized && document.querySelector(".article_title h1")?.innerHTML != null) {
      const title = document.querySelector(".article_title h1")?.innerHTML;
      window.parent.document.title = `${title} - ${MAIN_PAGE_TITLE}`;
    }

    let script: HTMLScriptElement;
    if (!loading && !isStencilized && page && canLoadLegoScript) {
      // Using setTimeout 0 will avoid loading this KB Widget script synchronously in order to fix an issue where
      // Amazon UI and AmazonPageUI has to be loaded first
      setTimeout(() => {
        script = document.createElement("script");
        script.src = UrlHelper.getLegoJavascriptUrl();
        script.type = "text/javascript";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
      }, 0);
    }

    return () => {
      script?.remove();
    };
  }, [loading, page, canLoadLegoScript, isStencilized]);

  // The order this strictly arranged this way so that AMAZON_PAGE_UI_SRC loaded first to avoid having issues where
  // cannot access _namespace of undefined. The second script AMAZON_UI_SRC is making use of the first one
  return (
    <>
      {!isStencilized && (
        <Helmet>
          <script src={AMAZON_PAGE_UI_SRC} type="text/javascript" />
          {canLoadLegoScript && <script src={AMAZON_UI_SRC} type="text/javascript" />}
          <script src={JQUERY_SRC} type="text/javascript"></script>
          <link rel="stylesheet" type="text/css" href={UrlHelper.getLegoStylesUrl()} />
        </Helmet>
      )}
      <div className="lego-page-content">
        <LoadingScreen show={loading} />
        {page}
      </div>
    </>
  );
};

export default LegoPage;
